import {
  CellId,
  CommentId,
  ComponentImportCellId,
  ComponentVersionStub,
  DataConnectionId,
  GridElementId,
  GridLayoutId,
  HexId,
  HexVersionId,
  HexVersionString,
  KernelImage,
  KernelSize,
  MagicEventId,
  MagicKeyword,
  ProjectLanguage,
  ProjectTemplateForUI,
  ReviewRequestId,
  RichTextDocument,
  SmartEditType,
  StaticCellId,
  typedObjectEntries,
  typedObjectKeys,
} from "@hex/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import { isArray } from "lodash";
import memoize from "micro-memoize";
import { createSelector } from "reselect";

import { WarningsFragment } from "../../mutations/import.generated";
import { EditViewType } from "../../util/projectViewTypes.js";
import { RootState } from "../store";

import { CellLocation } from "./cell-location/index.js";
import { GridLayoutMP, hexVersionMPSelectors } from "./hexVersionMPSlice.js";

type WithCellId = {
  cellId: CellId;
};
type WithCellIdData<T> = WithCellId & {
  data: T;
};
type CellDataAction<D, T extends string = string> = PayloadAction<
  WithCellIdData<D>,
  T
>;
type CellAction = PayloadAction<WithCellId>;

export type CommentLocation = "project" | "app" | ReviewRequestId;

export enum ActionBarMode {
  MAGIC = "MAGIC",
  ADD_CELL = "ADD_CELL",
}

export type ActionBarState = {
  /**
   * Indicates whether or not the airlock is in "ADD_CELL" or "MAGIC" mode. We
   * track this separately from the cursor because we don't want changing this
   * mode to trigger the project to re-calculate its cell blocks.
   */
  mode: ActionBarMode;
  isOpen: boolean;
  location: CellLocation;
  /**
   * If true, then the action bar will not animate when closing. This gets reset
   * to the default value every time the prompt bar is opened, and should get
   * set via argument to `closeActionBar`.
   * @default false
   */
  disableCloseAnimation: boolean;
};

export type DraftAirlockState = {
  prompt: string;
  promptV2?: RichTextDocument;
  forceFocusPromptBar: number;
};

export const STILL_THINKING_THRESHOLD = 3000; // 3sec

export interface CellMagicState {
  smartEditLoaded: boolean; // Sucessfullly loaded a Magic edit into a cell
  smartEditLoading: boolean; // Loading a Magic edit into a cell
  stillThinking: boolean; // If it's still loading
  magicEventId?: MagicEventId;
  smartEditType: MagicKeyword | undefined; // "Edit" or "Generate"
  sideBySideDiff: boolean; // If the diff view is side-by-side (default is inline)
  magicPrompt?: RichTextDocument; // Current prompt
}

export interface MagicPromptBarState {
  activeMagicCellId: CellId | null;
  promptBarOpen: boolean;
  editMode: boolean;
}

const createInitialMagicCellState = (): CellMagicState => ({
  smartEditLoaded: false,
  smartEditLoading: false,
  stillThinking: false,
  magicEventId: undefined,
  smartEditType: undefined,
  sideBySideDiff: true,
  magicPrompt: undefined,
});

const INITIAL_MAGIC_CELL_STATE = createInitialMagicCellState();

type MagicCellPromptsState = {
  [cellId: string]: RichTextDocument | undefined;
};

type EmbedDialogState = {
  open: boolean;
  staticCellId: StaticCellId | null;
};

type CellModalState = {
  fullScreen?: boolean;
  cellId: CellId;
};

type CellSidebarState = {
  cellId: CellId;
};

export type ComponentImportAddCellConfig = {
  language: ProjectLanguage;
  location: CellLocation;
};

export type DeleteTabDialogState =
  | { open: false; gridLayoutToDelete?: undefined; elementCount?: undefined }
  | {
      open: true;
      gridLayoutToDelete: GridLayoutMP;
      elementCount: number;
    };

type ComponentImportDialogState = {
  open: boolean;
  selectedComponent: ComponentVersionStub | null;
  addCellConfig: ComponentImportAddCellConfig;
};

export type ComponentUpdateVersions = {
  cellId: CellId;
  componentImportCellId: ComponentImportCellId;
  hexId: HexId;
  currentHexVersionId: HexVersionId;
  currentHexVersion: HexVersionString;
  newHexVersionId: HexVersionId;
  newHexVersion: HexVersionString;
};

type ComponentUpdateDialogState = {
  open: boolean;
  componentUpdateVersions?: ComponentUpdateVersions;
};

type NewComponentSettings = {
  projectLanguage: ProjectLanguage;
  kernelSize: KernelSize;
  kernelImage: KernelImage;
};

type ComponentSidebarState = {
  open?: boolean;
  settings?: NewComponentSettings;
};

type CreateComponentSidebar = Record<
  HexVersionId,
  ComponentSidebarState | undefined
>;

type JumpState = {
  pos: number;
  history: CellId[];
  isPanelOpen: boolean;
};

type ReviewRequestDialogState =
  | {
      status: "initial" | "loading" | "success";
      errorMessage?: undefined;
    }
  | {
      status: "error";
      errorMessage: string;
    };

interface PublishDialogState {
  view: "settings" | "reviewRequest";
  versionName: string;
}

export type SearchTermDecoration = {
  cellId: CellId;
  lineIndex: number;
  match: {
    startIndex: number;
    endIndex: number;
  };
};

/**
 * The state of the project when there is a non-empty search term in the project search bar.
 * Tracks both the search term and selected (active) search term.
 */
export interface ProjectSearchState {
  /**
   * The current term that the user is searching in the logic view.
   */
  projectSearchTerm: string;
  // case match and whole word match represent the search config options in the sidebar.
  caseMatch: boolean;
  wholeWordMatch: boolean;
  /**
   * In project search mode, the user can cycle through the search results.
   * If there is a currently selected search result, include this as the focusedCellState
   * to provide additional cell highlighting.
   */
  selectedSearchItem?: SearchTermDecoration;
  /**
   * In project search mode, set the item that the user's cursor is hovering over.
   */
  activeHoverItem?: SearchTermDecoration;
}

type LogicViewSliceState = {
  embedDialog: EmbedDialogState;
  deleteTabDialog: DeleteTabDialogState;
  reviewRequestDialog: ReviewRequestDialogState;
  publishDialog: PublishDialogState;
  projectSearch: ProjectSearchState | null;
  openInputConfigCellId: CellId | null;
  cellModal: CellModalState | null;
  cellSidebar: CellSidebarState | null;
  // Using a record like this is basically the same as using a set
  // but this works a little bit better with redux dev tools
  selectedCellIds: Record<CellId, true | undefined>;
  mostRecentlySelectedCellId: CellId | null;
  focusedView: EditViewType | null;
  /**
   * Prefer using flags on events such as `evtModKey(evt)`.
   * Only meant for accessing keyboard state in handlers without events.
   */
  holdingMod: boolean;
  /**
   * Prefer using flags on events such as `evt.shift`.
   * Only meant for accessing keyboard state in handlers without events.
   */
  holdingShift: boolean;
  currentlyEditingTabId: GridLayoutId | null;
  activeGridElementIds: GridElementId[];
  focusedCommentId: CommentId | null;
  focusedCommentLocation: CommentLocation | null;
  editMode: boolean;
  versionHistorySelectedVersion: HexVersionString | null;
  importWarnings: WarningsFragment | null;
  componentImportDialog: ComponentImportDialogState;
  componentUpdateDialog: ComponentUpdateDialogState;
  createComponentSidebar: CreateComponentSidebar;

  actionBarState: ActionBarState;
  draftAirlockState: DraftAirlockState;

  magicPromptBarState: MagicPromptBarState;
  cellIdToMagicState: Record<CellId, CellMagicState | undefined>;
  magicCellPrompts: MagicCellPromptsState;

  previewState?: ProjectTemplateForUI | null;
  jumpState: JumpState;
  /**
   * We use two pieces of state to track the last used data connection, which are updated
   * any time a user selects a data connection via the `DataConnectionSelect` component
   *  - the most recently used "real" data connection ID (i.e. corresponding to a data warehouse,
   *     not dataframe SQL)
   *  - whether the most recent data connection selection was for dataframe SQL
   * These values are initialized on page load based on any existing SQL cells. If there are no
   * SQL cells, `lastUsedDataConnection` falls back to:
   *  - an arbitrarily selected project-level connection, or if none:
   *  - an arbitrarily selected workspace connection already imported to the project, or if none:
   *  - the org default connection, or if none:
   *  - an arbitrarily selected workspace connection
   */
  lastUsedDataConnection?: DataConnectionId;
  lastUsedDataframeSql: boolean;
  /**
   * Used in the review request modal to track the versions being compared.
   */
  diffingVersions: {
    newVersion: HexVersionString;
    oldVersion: HexVersionString;
  } | null;
  /**
   * In order to correctly handle project search view focus when the cmd+f hotkey is pressed, we use an incrementing counter in Redux
   * and subscribe to this counter's changes in SidebarWrapper.tsx.
   * Whenever we wish to focus on the project search, we will increment this counter.
   * If a user selects text in a cell and then opens the project search, we should
   * change the search to include that term.
   */
  projectSearchViewFocus: {
    counter: number;
    selectedText: string | null;
  };
  /**
   * Expand to replace when the replace hotkey is selected.
   */
  projectSearchReplaceViewCounter: number;
  /**
   * The cellId that we scrolled to from another action, such as from the Project search view.
   * When selecting a search item from project search, we should (1) scroll to the cell and (2)
   * show an animation that is similar to the selection/focus state for cells without hijacking focus.
   */
  scrolledToViewCellId: CellId | null;
};

const initialLogicViewState: LogicViewSliceState = {
  embedDialog: {
    open: false,
    staticCellId: null,
  },
  deleteTabDialog: {
    open: false,
  },
  reviewRequestDialog: {
    status: "initial",
  },
  publishDialog: {
    view: "settings",
    versionName: "",
  },
  projectSearch: null,
  currentlyEditingTabId: null,
  activeGridElementIds: [],
  openInputConfigCellId: null,
  cellModal: null,
  cellSidebar: null,
  selectedCellIds: {},
  mostRecentlySelectedCellId: null,
  focusedView: null,
  holdingMod: false,
  holdingShift: false,
  focusedCommentId: null,
  focusedCommentLocation: null,
  editMode: true,
  versionHistorySelectedVersion: null,
  importWarnings: null,
  componentImportDialog: {
    open: false,
    selectedComponent: null,
    addCellConfig: {
      location: {
        type: "global",
      },
      language: ProjectLanguage.PYTHON,
    },
  },
  componentUpdateDialog: {
    open: false,
    componentUpdateVersions: undefined,
  },
  createComponentSidebar: {},

  actionBarState: {
    mode: ActionBarMode.ADD_CELL,
    location: {
      type: "global",
    },
    isOpen: false,
    disableCloseAnimation: false,
  },
  draftAirlockState: {
    prompt: "",
    promptV2: undefined,
    forceFocusPromptBar: 0,
  },

  magicPromptBarState: {
    promptBarOpen: false,
    activeMagicCellId: null,
    editMode: false,
  },
  cellIdToMagicState: {},
  magicCellPrompts: {},

  jumpState: {
    pos: 0,
    history: [],
    isPanelOpen: false,
  },
  lastUsedDataConnection: undefined,
  lastUsedDataframeSql: false,
  diffingVersions: null,
  projectSearchReplaceViewCounter: 0,
  projectSearchViewFocus: {
    counter: 0,
    selectedText: null,
  },
  scrolledToViewCellId: null,
};

const logicViewSlice = createSlice({
  name: "logicView",
  initialState: initialLogicViewState,
  reducers: {
    setLastUsedDataConnection: (
      state,
      action: PayloadAction<DataConnectionId | undefined>,
    ) => {
      state.lastUsedDataConnection = action.payload;
    },
    setLastUsedDataframeSql: (state, action: PayloadAction<boolean>) => {
      state.lastUsedDataframeSql = action.payload;
    },
    openEmbedDialog: (state, action: PayloadAction<StaticCellId | null>) => {
      state.embedDialog.open = true;
      state.embedDialog.staticCellId = action.payload;
    },
    closeEmbedDialog: (state, _action: PayloadAction) => {
      state.embedDialog.open = false;
      state.embedDialog.staticCellId = null;
    },
    setDeleteTabDialog: (
      state,
      action: PayloadAction<DeleteTabDialogState>,
    ) => {
      state.deleteTabDialog = action.payload;
    },
    setReviewRequestDialogStatus: (
      state,
      action: PayloadAction<
        "initial" | "loading" | { errorMessage: string } | "success"
      >,
    ) => {
      if (typeof action.payload === "string") {
        state.reviewRequestDialog.status = action.payload;
        delete state.reviewRequestDialog.errorMessage;
      } else {
        state.reviewRequestDialog.status = "error";
        state.reviewRequestDialog.errorMessage = action.payload.errorMessage;
      }
    },
    setPublishDialogView: (
      state,
      action: PayloadAction<PublishDialogState["view"]>,
    ) => {
      state.publishDialog.view = action.payload;
    },
    setPublishDialogVersionName: (state, action: PayloadAction<string>) => {
      state.publishDialog.versionName = action.payload;
    },
    setProjectSearch: (
      state,
      action: PayloadAction<ProjectSearchState | null>,
    ) => {
      state.projectSearch = action.payload;
    },
    setImportWarnings: (state, action: PayloadAction<WarningsFragment>) => {
      state.importWarnings = castDraft(action.payload);
    },
    clearImportWarnings: (state, _action: PayloadAction) => {
      state.importWarnings = null;
    },
    setOpenInputConfigCellId: (state, action: PayloadAction<CellId | null>) => {
      state.openInputConfigCellId = action.payload;
    },
    setCurrentlyEditingTabId: (
      state,
      action: PayloadAction<GridLayoutId | null>,
    ) => {
      state.currentlyEditingTabId = action.payload;
    },
    setActiveGridElementIds: (
      state,
      action: PayloadAction<
        | GridElementId[]
        | ((currGridElementIds: GridElementId[]) => GridElementId[])
      >,
    ) => {
      if (isArray(action.payload)) {
        state.activeGridElementIds = action.payload;
      } else {
        const newGridElementIds = action.payload(state.activeGridElementIds);
        if (newGridElementIds !== state.activeGridElementIds) {
          state.activeGridElementIds = newGridElementIds;
        }
      }
    },
    setSelectedCellIds: (state, action: PayloadAction<CellId[]>) => {
      const newSelectionIds = new Set(action.payload);
      for (const cellId of typedObjectKeys(state.selectedCellIds)) {
        if (!newSelectionIds.has(cellId)) {
          delete state.selectedCellIds[cellId];
        }
      }
      for (const cellId of action.payload) {
        state.selectedCellIds[cellId] = true;
      }

      if (action.payload.length > 0) {
        state.mostRecentlySelectedCellId =
          action.payload[action.payload.length - 1];
      } else if (state.mostRecentlySelectedCellId != null) {
        state.mostRecentlySelectedCellId = null;
      }

      state.scrolledToViewCellId = null;
    },
    addSelectedCellIds: (state, action: PayloadAction<CellId[]>) => {
      for (const cellId of action.payload) {
        state.selectedCellIds[cellId] = true;
      }

      if (action.payload.length > 0) {
        state.mostRecentlySelectedCellId =
          action.payload[action.payload.length - 1];
      } else if (state.mostRecentlySelectedCellId != null) {
        state.mostRecentlySelectedCellId = null;
      }

      state.scrolledToViewCellId = null;
    },
    removeSelectedCellIds: (state, action: PayloadAction<CellId[]>) => {
      for (const cellId of action.payload) {
        if (state.selectedCellIds[cellId]) {
          delete state.selectedCellIds[cellId];
        }
      }
    },
    setMostRecentlySelectedCellId: (
      state,
      action: PayloadAction<CellId | null>,
    ) => {
      state.mostRecentlySelectedCellId = action.payload;
      state.scrolledToViewCellId = null;
    },
    setFocusedView: (state, action: PayloadAction<EditViewType | null>) => {
      state.focusedView = action.payload;
    },
    setHoldingMod: (state, action: PayloadAction<boolean>) => {
      state.holdingMod = action.payload;
    },
    setHoldingShift: (state, action: PayloadAction<boolean>) => {
      state.holdingShift = action.payload;
    },
    setCellModal: (state, action: PayloadAction<CellModalState | null>) => {
      state.cellModal = action.payload;
    },
    setCellSidebar: (state, action: PayloadAction<CellSidebarState | null>) => {
      state.cellSidebar = action.payload;
    },
    setFocusedCommentId: (state, action: PayloadAction<CommentId | null>) => {
      state.focusedCommentId = action.payload;
    },
    setFocusedCommentLocation: (
      state,
      action: PayloadAction<CommentLocation | null>,
    ) => {
      state.focusedCommentLocation = action.payload;
    },
    setEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    setVersionHistorySelectedVersion: (
      state,
      action: PayloadAction<HexVersionString | null>,
    ) => {
      state.versionHistorySelectedVersion = action.payload;
    },
    openComponentImportDialog: (
      state,
      action: PayloadAction<ComponentImportAddCellConfig>,
    ) => {
      state.componentImportDialog.open = true;
      state.componentImportDialog.addCellConfig = action.payload;
    },
    closeComponentImportDialog: (state, _action: PayloadAction) => {
      state.componentImportDialog.open = false;
      state.componentImportDialog.selectedComponent = null;
      state.componentImportDialog.addCellConfig = {
        location: {
          type: "global",
        },
        language: ProjectLanguage.PYTHON,
      };
    },
    setSelectedComponent: (
      state,
      action: PayloadAction<ComponentVersionStub | null>,
    ) => {
      state.componentImportDialog.selectedComponent = action.payload;
    },
    openComponentUpdateDialog: (
      state,
      action: PayloadAction<ComponentUpdateVersions>,
    ) => {
      state.componentUpdateDialog.open = true;
      state.componentUpdateDialog.componentUpdateVersions = action.payload;
    },
    closeComponentUpdateDialog: (state, _action: PayloadAction) => {
      state.componentUpdateDialog.open = false;
    },
    openCreateComponentSidebar: (
      state,
      action: PayloadAction<HexVersionId>,
    ) => {
      const sidebarState = (state.createComponentSidebar[action.payload] ??= {
        open: true,
      });
      sidebarState.open = true;
    },
    closeCreateComponentSidebar: (
      state,
      action: PayloadAction<HexVersionId>,
    ) => {
      const sidebarState = (state.createComponentSidebar[action.payload] ??= {
        open: false,
      });
      sidebarState.open = false;
    },
    setSettingsForNewComponent: (
      state,
      action: PayloadAction<{
        hexVersionId: HexVersionId;
        settings?: NewComponentSettings;
      }>,
    ) => {
      const sidebarState = (state.createComponentSidebar[
        action.payload.hexVersionId
      ] ??= {
        settings: action.payload.settings,
      });

      sidebarState.settings = action.payload.settings;
    },
    openActionBar: (
      state,
      action: PayloadAction<
        | {
            mode: ActionBarMode;
            location: CellLocation;
          }
        | undefined
      >,
    ) => {
      state.actionBarState.isOpen = true;
      state.actionBarState.disableCloseAnimation = false;
      if (action.payload != null) {
        state.actionBarState.mode = action.payload.mode;
        state.actionBarState.location = action.payload.location;
      }
    },
    setActionBarMode: (state, action: PayloadAction<ActionBarMode>) => {
      state.actionBarState.mode = action.payload;
    },
    setActionBarLocation: (state, action: PayloadAction<CellLocation>) => {
      state.actionBarState.location = action.payload;
    },
    closeActionBar: (
      state,
      action: PayloadAction<
        | {
            /**
             * @default false
             */
            disableCloseAnimation: boolean;
          }
        | undefined
      >,
    ) => {
      state.actionBarState.isOpen = false;
      state.actionBarState.disableCloseAnimation =
        action.payload?.disableCloseAnimation ?? false;
    },
    updateDraftAirlock: (
      state,
      action: PayloadAction<{
        prompt?: string;
        promptV2?: RichTextDocument;
      }>,
    ) => {
      const { prompt, promptV2 } = action.payload;

      if (prompt != null) {
        state.draftAirlockState.prompt = prompt;
      }
      if (promptV2 != null) {
        state.draftAirlockState.promptV2 = promptV2;
      }
    },
    focusPromptBar: (state) => {
      state.draftAirlockState.forceFocusPromptBar += 1;
    },
    setDraftAirlockPrompt: (state, action: PayloadAction<string>) => {
      state.draftAirlockState.prompt = action.payload;
    },
    setDraftAirlockPromptV2: (
      state,
      action: PayloadAction<RichTextDocument | undefined>,
    ) => {
      state.draftAirlockState.promptV2 = action.payload;
    },
    // magic state actions
    setActiveMagicCell: (state, action: PayloadAction<CellId | null>) => {
      state.magicPromptBarState.activeMagicCellId = action.payload;
    },
    setMagicEditMode: (state, action: PayloadAction<boolean>) => {
      state.magicPromptBarState.editMode = action.payload;
    },
    closeSingleCellPromptBar: (
      state,
      action: PayloadAction<{ keepMagicActivated: boolean }>,
    ) => {
      state.magicPromptBarState.activeMagicCellId = null;
      state.magicPromptBarState.editMode = false;
      state.magicPromptBarState.promptBarOpen =
        action.payload.keepMagicActivated;
    },
    setPromptBarOpen: (
      state,
      action: PayloadAction<{
        promptBarOpen: boolean;
        cellId?: CellId;
        editMode?: boolean;
      }>,
    ) => {
      state.magicPromptBarState.promptBarOpen = action.payload.promptBarOpen;

      // by default every time we close prompt bar we clear active magic cell
      if (!state.magicPromptBarState.promptBarOpen) {
        state.magicPromptBarState.activeMagicCellId = null;
      }

      if (action.payload.cellId) {
        state.magicPromptBarState.activeMagicCellId = action.payload.cellId;
      }
      if (action.payload.editMode != null) {
        state.magicPromptBarState.editMode = action.payload.editMode;
      }
    },
    setStillThinking: (state, action: CellDataAction<boolean>) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.stillThinking = action.payload.data;
    },
    setMagicEventId: (state, action: CellDataAction<MagicEventId>) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.magicEventId = action.payload.data;
    },
    setMagicPrompt: (
      state,
      action: CellDataAction<RichTextDocument | undefined>,
    ) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.magicPrompt = action.payload.data;
    },
    toggleEditBarMode: (state, action: CellAction) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditType =
        magicCellState.smartEditType === SmartEditType.INSERT
          ? SmartEditType.CUSTOM
          : SmartEditType.INSERT;
    },
    closeEditBar: (state, action: CellAction) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditType = undefined;
    },
    setSmartEditLoading: (state, action: CellDataAction<boolean>) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditLoading = action.payload.data;
    },
    setSideBySideDiff: (state, action: CellDataAction<boolean>) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.sideBySideDiff = action.payload.data;
    },
    setSmartEditType: (
      state,
      action: CellDataAction<MagicKeyword | undefined>,
    ) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditType = action.payload.data;
    },
    setSmartEditSuccessfullyLoaded: (state, action: CellAction) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditLoaded = true;
      magicCellState.smartEditLoading = false;
      magicCellState.stillThinking = false;
    },
    setSmartEditErrored: (state, action: CellAction) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditLoaded = false;
      magicCellState.smartEditLoading = false;
      magicCellState.stillThinking = false;
    },
    clearSmartEdit: (
      state,
      action: CellDataAction<{ clearPrompt: boolean }>,
    ) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());

      magicCellState.smartEditLoaded = false;
      magicCellState.magicEventId = undefined;
      if (action.payload.data.clearPrompt) {
        magicCellState.magicPrompt = undefined;
      }
    },
    clearMagicState: (state, action: CellAction) => {
      const magicCellState = (state.cellIdToMagicState[
        action.payload.cellId
      ] ??= createInitialMagicCellState());
      // Keeping these so we can clear state without closing the edit bar
      //  - magicEditBarOpen
      //  - magicEditBarMode
      magicCellState.smartEditLoaded = false;
      magicCellState.stillThinking = false;
      magicCellState.magicEventId = undefined;
      magicCellState.smartEditLoading = false;
      magicCellState.smartEditType = undefined;
      magicCellState.sideBySideDiff = false;
      magicCellState.magicPrompt = undefined;
    },
    setMagicCellPrompt: (
      state,
      action: CellDataAction<{
        prompt: RichTextDocument | undefined;
      }>,
    ) => {
      const {
        payload: {
          cellId,
          data: { prompt },
        },
      } = action;
      state.magicCellPrompts[cellId] = prompt;
    },
    removeMagicCellPrompt: (state, action: CellAction) => {
      const {
        payload: { cellId },
      } = action;
      delete state.magicCellPrompts[cellId];
    },
    setPreview: (state, action: PayloadAction<ProjectTemplateForUI | null>) => {
      state.previewState = action.payload;
    },
    pushJumpHistory: (state, action: PayloadAction<CellId>) => {
      const maxHistoryEntries = 100;

      // only push to jump history stack if the new cell is different from the last one
      const curLastCellId = state.jumpState.history[0];
      const newLastCellId = action.payload;
      if (curLastCellId !== newLastCellId) {
        state.jumpState.history = [
          action.payload,
          ...state.jumpState.history.slice(
            state.jumpState.pos,
            maxHistoryEntries - 1,
          ),
        ];
        state.jumpState.pos = 0;
      }
    },
    moveJumpHistory: (
      state,
      action: PayloadAction<{
        pos: number;
        history: CellId[];
      }>,
    ) => {
      state.jumpState.pos = Math.min(
        Math.max(action.payload.pos, 0),
        state.jumpState.history.length - 1,
      );
      state.jumpState.history = action.payload.history;
    },
    setJumpHistoryPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.jumpState.isPanelOpen = action.payload;
    },
    setDiffingVersions: (
      state,
      action: PayloadAction<{
        newVersion: HexVersionString;
        oldVersion: HexVersionString;
      } | null>,
    ) => {
      state.diffingVersions = action.payload;
    },
    focusProjectSearchInput: (
      state,
      action: PayloadAction<{
        selectedText: string | null;
      }>,
    ) => {
      state.projectSearchViewFocus = {
        counter: state.projectSearchViewFocus.counter + 1,
        selectedText: action.payload.selectedText,
      };
    },
    setScrolledToViewCellId: (state, action: PayloadAction<CellId | null>) => {
      state.scrolledToViewCellId = action.payload;
    },
    focusProjectSearchReplaceInput: (state) => {
      state.projectSearchReplaceViewCounter =
        state.projectSearchReplaceViewCounter + 1;
    },
  },
});
export const selectPreviewState = (
  state: RootState,
): ProjectTemplateForUI | null => state.logicView.previewState ?? null;
export const selectOpenInputConfigCellId = (state: RootState): CellId | null =>
  state.logicView.openInputConfigCellId;
export const selectCellModal = (state: RootState): CellModalState | null =>
  state.logicView.cellModal;
export const selectCellSidebar = (state: RootState): CellSidebarState | null =>
  state.logicView.cellSidebar;

const selectSelectedCellIds = (
  state: RootState,
): Record<CellId, true | undefined> => state.logicView.selectedCellIds;
const selectSelectedCellIdSet = createSelector(
  selectSelectedCellIds,
  (selectedCellIdState: Record<CellId, true | undefined>) => {
    const selectedCellIds = new Set<CellId>();
    for (const [cellId, selected] of typedObjectEntries(selectedCellIdState)) {
      if (selected) {
        selectedCellIds.add(cellId);
      }
    }
    return selectedCellIds;
  },
);
const selectNumberOfCellsSelected = (state: RootState): number =>
  selectSelectedCellIdSet(state).size;
const selectIsCellSelected = (state: RootState, cellId: CellId): boolean =>
  selectSelectedCellIdSet(state).has(cellId);
const selectIsCellMultiselected = (state: RootState, cellId: CellId): boolean =>
  selectNumberOfCellsSelected(state) > 1 && selectIsCellSelected(state, cellId);

const selectSortedSelectedCells = createSelector(
  (state: RootState, _hexVersionId: HexVersionId) =>
    selectSelectedCellIdSet(state),
  (state: RootState, hexVersionId: HexVersionId) =>
    hexVersionMPSelectors
      .getCellSelectors(hexVersionId)
      .selectFlattenedSorted(state),
  (selectedCellIds, sortedCells) =>
    sortedCells.filter(({ id }) => selectedCellIds.has(id)),
);

export const cellSelectionSelectors = {
  selectSelectedCellIdSet,
  selectNumberOfCellsSelected,
  selectIsCellSelected,
  selectIsCellMultiselected,
  selectSortedSelectedCells,
};

export const selectLastUsedDataConnection = (
  state: RootState,
): DataConnectionId | undefined => state.logicView.lastUsedDataConnection;

export const selectActionBarState = (state: RootState): ActionBarState =>
  state.logicView.actionBarState;
export const selectActionBarMode = (state: RootState): ActionBarMode =>
  selectActionBarState(state).mode;

const selectDraftAirlockState = (state: RootState): DraftAirlockState =>
  state.logicView.draftAirlockState;
const selectForceFocusPromptBar = (state: RootState): number =>
  state.logicView.draftAirlockState.forceFocusPromptBar;

const selectMagicPromptBarState = (state: RootState): MagicPromptBarState =>
  state.logicView.magicPromptBarState;
const selectMagicCellState = (
  state: RootState,
  cellId: CellId,
): CellMagicState =>
  state.logicView.cellIdToMagicState[cellId] ?? INITIAL_MAGIC_CELL_STATE;
const selectMagicCellPrompt = (
  state: RootState,
  cellId: CellId,
): RichTextDocument | undefined => state.logicView.magicCellPrompts[cellId];

export const magicSelectors = {
  selectDraftAirlockState,
  selectForceFocusPromptBar,
  selectMagicPromptBarState,
  selectMagicCellState,
  selectMagicCellPrompt,
};

export const selectEditMode = (state: RootState): boolean =>
  state.logicView.editMode;
export const selectVersionHistorySelectedVersion = (
  state: RootState,
): HexVersionString | null => state.logicView.versionHistorySelectedVersion;
export const selectEmbedDialogState = (state: RootState): EmbedDialogState =>
  state.logicView.embedDialog;
export const selectImportWarningsState = (
  state: RootState,
): WarningsFragment | null => state.logicView.importWarnings;
export const selectComponentImportDialogState = (
  state: RootState,
): ComponentImportDialogState => state.logicView.componentImportDialog;
export const selectReviewRequestDialogState = (
  state: RootState,
): ReviewRequestDialogState => state.logicView.reviewRequestDialog;
export const selectPublishDialogState = (
  state: RootState,
): PublishDialogState => state.logicView.publishDialog;
export const selectProjectSearchState = (
  state: RootState,
): ProjectSearchState | null => state.logicView.projectSearch;

export const selectComponentUpdateDialogState = (
  state: RootState,
): ComponentUpdateDialogState => state.logicView.componentUpdateDialog;

export const createComponentSidebarSelectors = memoize(
  (hexVersionId: HexVersionId) => {
    return {
      selectCreateComponentSidebarSettings: (state: RootState) => {
        return state.logicView.createComponentSidebar[hexVersionId]?.settings;
      },
      selectCreateComponentSidebarOpen: (state: RootState) => {
        return (
          state.logicView.createComponentSidebar[hexVersionId]?.open ?? false
        );
      },
    };
  },
);

export const logicViewReducer = logicViewSlice.reducer;

export const magicActions = {
  setActiveMagicCell: logicViewSlice.actions.setActiveMagicCell,
  setMagicEditMode: logicViewSlice.actions.setMagicEditMode,
  closeSingleCellPromptBar: logicViewSlice.actions.closeSingleCellPromptBar,
  setPromptBarOpen: logicViewSlice.actions.setPromptBarOpen,
  setStillThinking: logicViewSlice.actions.setStillThinking,
  setMagicEventId: logicViewSlice.actions.setMagicEventId,
  setMagicPrompt: logicViewSlice.actions.setMagicPrompt,
  toggleEditBarMode: logicViewSlice.actions.toggleEditBarMode,
  closeEditBar: logicViewSlice.actions.closeEditBar,
  setSmartEditLoading: logicViewSlice.actions.setSmartEditLoading,
  setSideBySideDiff: logicViewSlice.actions.setSideBySideDiff,
  setSmartEditType: logicViewSlice.actions.setSmartEditType,
  setSmartEditSuccessfullyLoaded:
    logicViewSlice.actions.setSmartEditSuccessfullyLoaded,
  setSmartEditErrored: logicViewSlice.actions.setSmartEditErrored,
  clearSmartEdit: logicViewSlice.actions.clearSmartEdit,
  clearMagicState: logicViewSlice.actions.clearMagicState,
  setMagicCellPrompt: logicViewSlice.actions.setMagicCellPrompt,
  removeMagicCellPrompt: logicViewSlice.actions.removeMagicCellPrompt,
  setDraftAirlockPrompt: logicViewSlice.actions.setDraftAirlockPrompt,
  setDraftAirlockPromptV2: logicViewSlice.actions.setDraftAirlockPromptV2,
};

export const {
  addSelectedCellIds,
  clearImportWarnings,
  closeActionBar,
  closeComponentImportDialog,
  closeComponentUpdateDialog,
  closeCreateComponentSidebar,
  closeEmbedDialog,
  focusProjectSearchInput,
  focusProjectSearchReplaceInput,
  focusPromptBar,
  moveJumpHistory,
  openActionBar,
  openComponentImportDialog,
  openComponentUpdateDialog,
  openCreateComponentSidebar,
  openEmbedDialog,
  pushJumpHistory,
  removeSelectedCellIds,
  setActionBarLocation,
  setActionBarMode,
  setActiveGridElementIds,
  setCellModal,
  setCellSidebar,
  setCurrentlyEditingTabId,
  setDeleteTabDialog,
  setDiffingVersions,
  setEditMode,
  setFocusedCommentId,
  setFocusedCommentLocation,
  setFocusedView,
  setHoldingMod,
  setHoldingShift,
  setImportWarnings,
  setJumpHistoryPanelOpen,
  setLastUsedDataConnection,
  setLastUsedDataframeSql,
  setMostRecentlySelectedCellId,
  setOpenInputConfigCellId,
  setPreview,
  setProjectSearch,
  setPublishDialogVersionName,
  setPublishDialogView,
  setReviewRequestDialogStatus,
  setScrolledToViewCellId,
  setSelectedCellIds,
  setSelectedComponent,
  setSettingsForNewComponent,
  setVersionHistorySelectedVersion,
  updateDraftAirlock,
} = logicViewSlice.actions;
