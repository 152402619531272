import {
  DateTimeString,
  HexVersionId,
  HexVersionString,
  ReviewId,
  ReviewRequestId,
  ReviewRequestLinkId,
  UserId,
  asciiCompare,
  stableEmptyArray,
} from "@hex/common";
import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createReducer,
  createSlice,
} from "@reduxjs/toolkit";
import { castDraft } from "immer";
import memoize from "micro-memoize";

import {
  ReviewMpFragment,
  ReviewRequestLinkMpFragment,
  ReviewRequestMpFragment,
} from "../../review-request-multiplayer/ReviewRequestMPModel.generated";
import { RootState } from "../store.js";
import { getSelectorsForEntityState } from "../utils/entityAdapterSelectorCreator.js";
import { assertNonNull } from "../utils/types.js";

//#region action helper types
type WithReviewRequestId<T> = {
  reviewRequestId: ReviewRequestId;
  data: T;
};
export type ReviewRequestAction<D, T extends string = string> = PayloadAction<
  WithReviewRequestId<D>,
  T
>;

type SetFieldAction<T> = ReviewRequestAction<{
  key: keyof NonNullable<T>;
  value: unknown;
}>;
type SetEntityFieldPayload<T extends { id: string }> = {
  entityId: T["id"];
  key: keyof NonNullable<T>;
  value: unknown;
};
type SetEntityFieldAction<T extends { id: string }> = ReviewRequestAction<
  SetEntityFieldPayload<T>
>;

//#region types of data in redux store
export type ReviewRequestMP = Omit<
  ReviewRequestMpFragment,
  "reviewRequestLinks" | "reviews"
>;
export type ReviewMP = ReviewMpFragment;
export type ReviewRequestLinkMP = ReviewRequestLinkMpFragment;

//#region adapters for normalization of lists of elements

const reviewAdapter = createEntityAdapter<ReviewMP>({
  selectId: (review) => review.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});

const reviewRequestLinkAdapter = createEntityAdapter<ReviewRequestLinkMP>({
  selectId: (link) => link.id,
  sortComparer: (a, b) => asciiCompare(a.id, b.id),
});

//#region reducer for data for a single ReviewRequestMP instance

// main shape of state
export type ReviewRequestMPValue = {
  reviewRequest: ReviewRequestMP;
  reviews: EntityState<ReviewMP>;
  reviewRequestLinks: EntityState<ReviewRequestLinkMP>;
};

const reviewRequestMPValueSlice = createSlice({
  name: "reviewRequestMPValue",
  // This initial state is essentially meaningless,
  // Since this reducer is only called as a function directly by `reviewRequestMPReducer` and
  // we always should have a `initializeFromReviewRequestMPData` action to really initialize things.
  initialState: null as unknown as ReviewRequestMPValue,
  reducers: {
    // reviewRequest actions
    setReviewRequestField(state, action: SetFieldAction<ReviewRequestMP>) {
      const reviewRequest: ReviewRequestMP = state.reviewRequest;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (reviewRequest as Record<string, any>)[action.payload.data.key] =
        action.payload.data.value;
    },
    addHexVersion(
      state,
      {
        payload: {
          data: { createdDate, creatorId, hexVersionId, hexVersionString },
        },
      }: ReviewRequestAction<{
        hexVersionId: HexVersionId;
        hexVersionString: HexVersionString;
        createdDate: DateTimeString;
        creatorId: UserId;
      }>,
    ) {
      const existing = state.reviewRequest.hexVersions.find(
        (hv) => hv.id === hexVersionId,
      );
      if (existing != null) {
        existing.version = hexVersionString;
        existing.createdDate = createdDate;
        existing.creatorId = creatorId;
      } else {
        state.reviewRequest.hexVersions.push({
          __typename: "HexVersion",
          id: hexVersionId,
          version: hexVersionString,
          creatorId,
          createdDate,
        });
      }
    },
    // review actions
    upsertReview(state, action: ReviewRequestAction<ReviewMP>) {
      reviewAdapter.upsertOne(state.reviews, action.payload.data);
    },
    deleteReview(state, action: ReviewRequestAction<{ reviewId: ReviewId }>) {
      reviewAdapter.removeOne(state.reviews, action.payload.data.reviewId);
    },
    // reviewRequestLink actions
    upsertReviewRequestLink(
      state,
      action: ReviewRequestAction<ReviewRequestLinkMP>,
    ) {
      reviewRequestLinkAdapter.upsertOne(
        state.reviewRequestLinks,
        action.payload.data,
      );
    },
    deleteReviewRequestLink(
      state,
      action: ReviewRequestAction<{ reviewRequestLinkId: ReviewRequestLinkId }>,
    ) {
      reviewRequestLinkAdapter.removeOne(
        state.reviewRequestLinks,
        action.payload.data.reviewRequestLinkId,
      );
    },
    setReviewRequestLinkField(
      state,
      { payload: { data } }: SetEntityFieldAction<ReviewRequestLinkMP>,
    ) {
      reviewRequestLinkAdapter.updateOne(state.reviewRequestLinks, {
        id: data.entityId,
        changes: { [data.key]: data.value },
      });
    },
    // main initialization actions
    initializeFromReviewRequestMPData(
      _state,
      action: ReviewRequestAction<ReviewRequestMpFragment>,
    ) {
      const { reviewRequestLinks, reviews, ...reviewRequest } =
        action.payload.data;
      return {
        reviewRequest,
        reviews: reviewAdapter.setAll(reviewAdapter.getInitialState(), reviews),
        reviewRequestLinks: reviewRequestLinkAdapter.setAll(
          reviewRequestLinkAdapter.getInitialState(),
          reviewRequestLinks,
        ),
      };
    },
  },
});

//#region selectors
// We wrap each function that creates selectors in `memoize`. This has a few benefits:
//   - We don't have to construct a whole new object of selectors each call
//   - And more importantly, you get back the same selector instances each time you call it,
//     which means that the cache for reselect selectors will work globally and thus will only
//     have to run the computation one time (instead of once per component)
/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */
export const reviewRequestMPSelectors = {
  getReviewRequestSelectors: memoize((reviewRequestId: ReviewRequestId) => ({
    select: (state: RootState): ReviewRequestMP => {
      const reviewRequest =
        state.reviewRequestMP[reviewRequestId]?.reviewRequest;
      assertNonNull(
        reviewRequest,
        `Can't select reviewRequest with id ${reviewRequestId}`,
      );
      return reviewRequest;
    },
    safeSelect: (state: RootState): ReviewRequestMP | null =>
      state.reviewRequestMP[reviewRequestId]?.reviewRequest ?? null,
  })),
  getReviewSelectors: memoize((reviewRequestId: ReviewRequestId) =>
    getSelectorsForEntityState(
      reviewAdapter,
      (state) => state.reviewRequestMP[reviewRequestId]?.reviews,
    ),
  ),
  getReviewRequestLinkSelectors: memoize((reviewRequestId: ReviewRequestId) => {
    type LinkByUserIdMap = {
      [userId: UserId]: ReviewRequestLinkMpFragment[] | undefined;
    };

    const baseSelectors = getSelectorsForEntityState(
      reviewRequestLinkAdapter,
      (state) => state.reviewRequestMP[reviewRequestId]?.reviewRequestLinks,
    );

    const groupReviewRequestLinksByUserId = (
      state: RootState,
    ): LinkByUserIdMap => {
      const reviewRequestLinks = baseSelectors.selectAll(state) ?? [];
      const result: LinkByUserIdMap = {};
      for (const link of reviewRequestLinks) {
        if (link.target.__typename === "ReviewRequestLinkUserTarget") {
          const linksForUserId = result[link.target.userId] ?? [];
          result[link.target.userId] = [...linksForUserId, link];
        }
      }
      return result;
    };

    return {
      ...baseSelectors,
      selectByUserId: (state: RootState, userId: UserId) => {
        const linksMap = groupReviewRequestLinksByUserId(state);
        return linksMap[userId] ?? stableEmptyArray();
      },
    };
  }),
};
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

//#region full multi-ReviewRequestMP reducer
type ReviewRequestMpState = {
  [reviewRequestId: string]: ReviewRequestMPValue | undefined;
};

export const reviewRequestMPActions = {
  ...reviewRequestMPValueSlice.actions,
} as const;

const allActionTypes = new Set(
  // eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
  Object.values(reviewRequestMPActions).map((a) => a.type),
);

export const reviewRequestMPReducer = createReducer<ReviewRequestMpState>(
  {},
  (builder) =>
    builder.addMatcher(
      (action): action is ReviewRequestAction<unknown> =>
        allActionTypes.has(action.type),
      (state, action) => {
        state[action.payload.reviewRequestId] = castDraft(
          reviewRequestMPValueSlice.reducer(
            state[action.payload.reviewRequestId],
            action,
          ),
        );
      },
    ),
);
